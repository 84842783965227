import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import searchResults from './reducers/searchResults';
import requestInfoDialog from './reducers/requestInfoDialog';
import disclaimerDialog from './reducers/disclaimerDialog';
import language from './reducers/language';


export const history = createHashHistory();

const reducers = {
	router: connectRouter(history),
	searchResults: searchResults.reducer,
	requestInfoDialog: requestInfoDialog.reducer,
	disclaimerDialog: disclaimerDialog.reducer,
	language: language.reducer,
};

const middleware = [
	...getDefaultMiddleware(),
	routerMiddleware(history),
];

export const store = configureStore({
	reducer: reducers,
	middleware: () => middleware,
	devTools: process.env.NODE_ENV !== 'production',
});
