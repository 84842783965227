import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tab } from '@material-ui/core';
import Map from './Map';
import SearchForm from './SearchForm';
import SchedulesTable from './SchedulesTable';
import RoutesTable from './RoutesTable';
import { Panel, Content, BoxLeft, Header, TabBar } from './Styled';
import RequestInfoDialog from './RequestInfoDialog';
import { FormattedMessage } from 'react-intl';


const Results = () => {
	const { schedules: { routes: schedules, polylines, locatables, modalities }, search, routes: idcp } = useSelector(state => state.searchResults);
	const [ selectedSchedule, selectSchedule ] = useState(null);
	const [ routesShown, showRoutes ] = useState(true);
	const hideSchedules = search.cargoType !== 'container';
	const [ collapsedRows, setCollapsedRows ] = useState([]);

	const getDefaultSortField = () => {
		if (search.optimization) {
			if (search.optimization === 'EMISSIONS') {
				return 'totalEmissions';
			} else if (search.optimization === 'TIME') {
				return 'totalDuration';
			} else if (search.optimization === 'COSTS') {
				return 'totalCosts';
			}
		}

		return 'totalDistance';
	};

	const [ order, setOrder ] = useState('asc');
	const [ orderBy, setOrderBy ] = useState(getDefaultSortField());

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler = property => event => {
		handleRequestSort(event, property);
	};

	const [ orderRoutes, setOrderRoutes ] = useState('asc');
	const [ orderByRoutes, setOrderByRoutes ] = useState('totalShips');

	const handleRequestSortRoutes = (event, property) => {
		const isAsc = orderByRoutes === property && orderRoutes === 'asc';
		setOrderRoutes(isAsc ? 'desc' : 'asc');
		setOrderByRoutes(property);
	};

	const createSortHandlerRoutes = property => event => {
		handleRequestSortRoutes(event, property);
	};

	return (
		<>
			<Box display="flex" flexGrow="1" boxSizing='border-box' alignItems={'stretch'}>
				<BoxLeft>
					<Header>
						<SearchForm />
					</Header>

					<Content>
						{!hideSchedules && <TabBar value={routesShown ? 0 : 1}>
							<Tab label={<FormattedMessage id="TABS.ROUTES" />} onClick={() => showRoutes(true)} />
							<Tab label={<FormattedMessage id="TABS.SCHEDULES" />} onClick={() => showRoutes(false)} />
						</TabBar>}
						{(!routesShown)
							? <SchedulesTable
								schedules={schedules}
								selectSchedule={selectSchedule}
								selectedSchedule={selectedSchedule}
								locatables={locatables}
								modalities={modalities}
								collapsedRows={collapsedRows}
								setCollapsedRows={setCollapsedRows}
								order={order}
								orderBy={orderBy}
								createSortHandler={createSortHandler} />
							: <RoutesTable
								routes={idcp}
								selectRoute={() => {}}
								selectedRoute={null}
								collapsedRows={[]}
								setCollapsedRows={() => {}}
								order={orderRoutes}
								orderBy={orderByRoutes}
								createSortHandler={createSortHandlerRoutes} />}
					</Content>
				</BoxLeft>

				<Box flex={1} display="flex" boxSizing="border-box">
					<Panel bgcolor="#FFF" width="100%">
						<Map
							routesShown={routesShown}
							routes={idcp}
							schedules={schedules || []}
							locatables={locatables}
							polylines={polylines}
							selectedSchedule={selectedSchedule} />
					</Panel>
				</Box>
			</Box>
			<RequestInfoDialog />
		</>
	);
};

export default Results;
