import { createMuiTheme } from '@material-ui/core/styles';
// eslint-disable-next-line no-unused-vars
const ceva = {
	primary: {
		main: '#1D2546',
		dark: '#151C38',
	},
	secondary: {
		main: '#E30613',
		dark: '#BA111B',
	},
	text: {
		secondary: '#1D2546',
	},
};

const bvb = {
	primary: {
		main: '#00325c',
	},
	secondary: {
		main: '#009cda',
	},
	text: {
		secondary: '#1d2546',
	},
};


export default createMuiTheme({
	palette: bvb,
});
