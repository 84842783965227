import { createSlice } from '@reduxjs/toolkit';
import { detectLanguage, mappingMoment } from '../LocaleWrapper';
import moment from 'moment';

const lang = detectLanguage('en');
moment.locale(mappingMoment[lang]);
const language = createSlice({
	name: 'language',
	initialState: {
		key: lang,
	},
	reducers: {
		setLanguage: (state, { payload }) => {
			moment.locale(mappingMoment[payload]);
			return {
				...state,
				key: payload,
			};
		},
	},
});

export default language;
