import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import 'moment/locale/nl';
import 'moment/locale/en-gb';

const en = require('./languages/en.json');
const nl = require('./languages/nl.json');

const fallback = 'en';
const mapping = {
	'en': en,
	'nl': nl,
};
export const mappingMoment = {
	'en': 'en-gb',
	'nl': 'nl',
};

const getMessages = language => {
	return mapping.hasOwnProperty(language) ? mapping[language] : fallback;
};

export const detectLanguage = () => {
	const key = (navigator.language || navigator.userLanguage || fallback).substring(0, 2);
	return mapping.hasOwnProperty(key) ? key : fallback;
};

export default function LocaleWrapper({ children }) {
	const language = useSelector(state => state.language.key);

	return (
		<IntlProvider locale={language} messages={getMessages(language)}>
			{children}
		</IntlProvider>
	);
}
