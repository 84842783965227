import React from 'react';
import sluiceImage from './sluice.png';
import { Tooltip } from '@material-ui/core';
import { DurationFormatter } from './formatters';
import { FormattedMessage } from 'react-intl';


const TooltipContents = ({ sluice }) => {
	return <React.Fragment>
		<div>{sluice.description}</div>
		<DurationFormatter duration={sluice.waitTime} showMinutes /> <FormattedMessage id={'SLUICEMARKER.WAITINGTIME'} />
	</React.Fragment>;
};

export default function ReactMarker({ lat, lng, sluice }) {
	return <Tooltip title={<TooltipContents sluice={sluice} />}><div
		style={{
			backgroundColor: '#FFF',
			border: '3px solid #104E8B',
			width: '20px',
			height: '20px',
			borderRadius: '20px',
			position: 'absolute',
			transform: 'translate(-50%, -50%)',
			background: `url('${sluiceImage}')`,
			backgroundSize: 'cover',
		}}  /></Tooltip>;
}



