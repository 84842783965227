import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import disclaimerDialog from '../../reducers/disclaimerDialog';
import { FormattedMessage } from 'react-intl';

function linesToParagraphs(...nodes) {
	return nodes
		.map(node => typeof node === 'string' ?
			node.split('\n').map((text, i) => <p key={i}>{text}</p>) : node)
		.reduce((obj, node) => obj.concat(node), []);
}

export default function DisclaimerDialog() {
	const dispatch = useDispatch();
	const showDisclaimerDialog = useSelector(state => state.disclaimerDialog.shown);
	const hide = () => dispatch(disclaimerDialog.actions.hide());
	return <Dialog open={showDisclaimerDialog}>
		<DialogTitle><FormattedMessage id="DISCLAIMER.TITLE" /></DialogTitle>
		<DialogContent>
			<DialogContentText>
				<FormattedMessage id="DISCLAIMER">{linesToParagraphs}</FormattedMessage>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={hide} color="primary">
				<FormattedMessage id="Close" />
			</Button>
		</DialogActions>
	</Dialog>;
}
