import React from 'react';
import moment from 'moment';
import get from 'lodash.get';
import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faShip, faTrain, faPlane, faClock, faEuroSign, faCloud } from '@fortawesome/free-solid-svg-icons';

import { Chip } from '@material-ui/core';

export const WaterwayClassFormatter = ({ waterwayClass }) => {
	const mapping = {
		1: 'I',
		2: 'II',
		3: 'III',
		4: 'IV',
		5: 'V',
		5.1: 'Va',
		5.2: 'Vb',
		6: 'VI',
		6.1: 'VIa',
		6.2: 'VIb',
		6.3: 'VIc',
		7: 'VII',
		8: 'VII',
	};

	return mapping[waterwayClass] || '?';
};

export const DistanceFormatter = ({ distance }) => <span>{distance.toFixed(2)} km</span>;

export const DateFormatter = ({ date }) => moment(date).format('DD-MM HH:mm');

export const DurationFormatter = ({ duration, showMinutes }) => {
	const minutes = Math.floor(duration % 60);
	const hours = Math.floor((duration / 60) % 24);
	const days = Math.floor(duration / 60 / 24);

	const strings = [];
	if (days > 0) { strings.push(`${days} ${days > 1 ? 'days' : 'day'}`); }
	if (hours > 0 || showMinutes) { strings.push(`${(hours + '').padStart(2, '0')}${true ? `:${(minutes + '').padStart(2, '0')}` : ''}h`); }
	if (!strings.length) { strings.push('Less than an hour'); }

	return <span>{strings.join(' ')}</span>;
};

export const ModalityFormatter = ({ modalities, schedule }) => {
	const modalitiesOnLegs = [];

	schedule.legs.forEach(leg => {
		leg.modalities.forEach(legModality => {
			if (!modalitiesOnLegs.find(m => m === legModality)) {
				modalitiesOnLegs.push(legModality);
			}
		});
	});

	return modalitiesOnLegs.map(id => {
		const modality = modalities.find(m => m.id === id).externalId;
		const style = { marginRight: '5px', fontSize: '12px' };

		if (modality === 'SHIP' || modality === 'BARGE' || modality === 'SHORTSEA' || modality === 'DEEPSEA') {
			return <FontAwesomeIcon key={id} icon={faShip} style={style} />;
		} else if (modality === 'TRUCK') {
			return <FontAwesomeIcon key={id} icon={faTruck} style={style} />;
		} else if (modality === 'TRAIN' || modality === 'TRAIN_ELECTRIC') {
			return <FontAwesomeIcon key={id} icon={faTrain} style={style} />;
		} else if (modality === 'AIRCRAFT') {
			return <FontAwesomeIcon key={id} icon={faPlane} style={style} />;
		} else {
			return null;
		}
	});
};

export const CarbonEmissionFormatter = ({ emission }) => <span>{emission ? emission.toFixed(2) : 0} kg CO<sub>2</sub></span>;

export const CostFormatter = ({ cost }) => cost ? <span>&#8364;{cost ? cost.toFixed(2) : 0}</span> : '-';

const Label = Styled((props) => <Chip {...props}>{props.children}</Chip>)`
	background-color: ${(props) => props.bgColor};
	margin-right: 5px;
	width: 24px;

	svg {
		color: #FFF;
		font-size: 12px;
		margin-left: 12px;
	}
`;

export const ScheduleLabels = ({ schedules, schedule }) => {
	const sortedSchedules = (field) => {
		const clone = [
			...schedules,
		];

		clone.sort((a, b) => {
			const keyA = get(a, field);
			const keyB = get(b, field);
			return (keyA < keyB) ? -1 : (keyA > keyB) ? 1 : 0;
		});

		return clone;
	};

	const isFastest = () => {
		const field = 'metric.totalDuration';
		const fastestSchedule = sortedSchedules(field)[0];

		if (get(schedule, field)) {
			return get(fastestSchedule, field) === get(schedule, field);
		}
	};

	const isCheapest = () => {
		const field = 'metric.totalMonetaryCost';
		const cheapestSchedule = sortedSchedules(field)[0];

		if (get(schedule, field)) {
			return get(cheapestSchedule, field) === get(schedule, field);
		}
	};

	const isGreenest = () => {
		const field = 'metric.totalEmissions';
		const greenestSchedule = sortedSchedules(field)[0];

		if (get(schedule, field)) {
			return get(greenestSchedule, field) === get(schedule, field);
		}
	};





	return <React.Fragment>
		{isFastest() && <Label size="small" icon={<FontAwesomeIcon icon={faClock} />} bgcolor="#ff9800" />}
		{isCheapest() && <Label size="small" icon={<FontAwesomeIcon icon={faEuroSign} />} bgcolor="#4791db" />}
		{isGreenest() && <Label size="small" icon={<FontAwesomeIcon icon={faCloud} />} bgcolor="#4caf50" />}
	</React.Fragment>;
};
