import React from 'react';
import Styled from 'styled-components';
import { useDispatch } from 'react-redux';
import language from '../../reducers/language';
import disclaimerDialog from '../../reducers/disclaimerDialog';
import CofanoLogoSrc from '../../img/cofano_logo.png';
import ENFlagSrc from '../../img/gb.png';
import NLFlagSrc from '../../img/nl.png';

const PoweredBy = Styled.div`
	display: inline-flex;
	box-sizing: border-box;
	flex-direction: row;
	float: right;
	height: 100%;
	padding: ${({ theme }) => theme.spacing(1) / 2}px;
	align-items: center;
	padding-right: ${({ theme }) => theme.spacing(1)}px;
`;
const PoweredByTitle = Styled.div`
	color: ${({ theme }) => theme.palette.primary.contrastText};
	margin-right: ${({ theme }) => theme.spacing(1)}px;
	font-size: ${({ theme }) => theme.typography.body2.fontSize};
	font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
	letter-spacing: ${({ theme }) => theme.typography.body2.letterSpacing};
`;
const PoweredByLogo = Styled.a`
	color: green;
	height: 100%;
	img {
		height: 100%;
	}
`;

const Disclaimer = Styled.a`
	display: inline-block;
	box-sizing: border-box;
	color: ${({ theme }) => theme.palette.primary.contrastText};
	line-height: 100%;
	height: 100%;
	margin-left: 8px;
	font-size: ${({ theme }) => theme.typography.body2.fontSize};
	font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
	letter-spacing: ${({ theme }) => theme.typography.body2.letterSpacing};
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;
const LanguageWrapper = Styled.div`
	display: inline-block;
	box-sizing: border-box;
	height: 100%;
	img {
		margin-right: 8px;
		height: 100%;
		cursor: pointer;
	}
	border-right: 1px solid white;
`;
const Menu = Styled.div`
	display: inline-flex;
	box-sizing: border-box;
	height: 100%;
	padding: ${({ theme }) => theme.spacing(1)}px;
	align-items: center;
`;

export default function Footer() {
	const dispatch = useDispatch();
	const setLanguage = (key) => dispatch(language.actions.setLanguage(key));
	const showDisclaimer = () => dispatch(disclaimerDialog.actions.show());

	return <>
		<Menu>
			<LanguageWrapper>
				<img src={ENFlagSrc} onClick={() => setLanguage('en')} alt="en" />
				<img src={NLFlagSrc} onClick={() => setLanguage('nl')} alt="nl" />
			</LanguageWrapper>
			<Disclaimer onClick={showDisclaimer}>Disclaimer</Disclaimer>
		</Menu>
		<PoweredBy>
			<PoweredByTitle>Powered By</PoweredByTitle>
			<PoweredByLogo href="https://www.cofano.nl/" target="blank"><img src={CofanoLogoSrc} alt="Cofano" /></PoweredByLogo>
		</PoweredBy>
	</>;
}
