import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const searchResults = createSlice({
	name: 'searchResults',
	initialState: {
		//...require('./testdata').default,
		selectedRoute: null,
		selectedSchedules: [],
	},
	reducers: {
		setResults: (state, { payload }) => ({
			...payload,
			// always select first row (#10)
			selectedRoute: payload.routes && payload.routes.length ? 0 : null,
			selectedSchedules: [],
		}),
		selectRoute: (state, { payload }) => ({
			...state,
			selectedRoute: payload,
		}),
		selectSchedule: (state, { payload }) => ({
			...state,
			selectedSchedules: state.selectedSchedules.indexOf(payload) > -1 ? state.selectedSchedules : [
				...state.selectedSchedules,
				payload,
			],
		}),
		deselectSchedule: (state, { payload }) => ({
			...state,
			selectedSchedules: _.filter(state.selectedSchedules, x => x !== payload),
		}),
	},
});

export default searchResults;
