import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import requestInfoDialog from '../../reducers/requestInfoDialog';
import { FormattedMessage } from 'react-intl';
import DataService from '../../services/DataService';

export default function RequestInfoDialog() {
	const dispatch = useDispatch();
	const { search } = useSelector(state => state.searchResults);
	const shown = useSelector(state => state.requestInfoDialog.shown);
	const hide = () => dispatch(requestInfoDialog.actions.hide());
	const [ loading, setLoading ] = useState(false);

	const { handleSubmit, values, setFieldValue, errors } = useFormik({
		initialValues: {
			email: null,
			remarks: null,
		},
		validationSchema: Yup.object().shape({
			email: Yup.string().required(),
		}),
		onSubmit: submitValues => {
			setLoading(true);

			const data = {
				from: search.fromAddress || search.from.name,
				to: search.toAddress || search.to.name,
				date: search.date,
			};
			DataService.sendRequestInfo(submitValues.email, submitValues.remarks, data).then(() => {
				setLoading(false);
				hide();
			});
		} });

	return <Dialog open={shown}>
		<form noValidate onSubmit={handleSubmit} component="form">
			<DialogTitle><FormattedMessage id="MOREINFO.TITLE" /></DialogTitle>
			<DialogContent>
				<DialogContentText>
					<FormattedMessage id="MOREINFO.CONTENT" />
				</DialogContentText>
				<TextField
					id="email"
					name="email"
					label={<FormattedMessage id="MOREINFO.EMAIL" />}
					value={values.email || ''}
					onChange={(event) => setFieldValue('email', event.target.value)}
					error={!!errors.email}
					type="email"
					margin="dense"
					autoFocus
					fullWidth />
				<TextField
					id="remarks"
					name="remarks"
					label={<FormattedMessage id="MOREINFO.ADDITIONALINFO" />}
					value={values.remarks || ''}
					onChange={(event) => setFieldValue('remarks', event.target.value)}
					error={!!errors.remarks}
					margin="dense"
					multiline
					fullWidth />
			</DialogContent>
			<DialogActions>
				<Button onClick={hide} color="secondary">
					<FormattedMessage id="MOREINFO.CANCEL" />
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={loading}>
					<FormattedMessage id="MOREINFO.REQUEST" />
					{loading &&	<CircularProgress color="#FFF" size={16} style={{ marginLeft: '10px' }} /> }
				</Button>
			</DialogActions>
		</form>
	</Dialog>;
}
