import React from 'react';
import Styled from 'styled-components';
import BVBLogoSrc from '../../img/bvb_logo.png';
import { Typography } from '@material-ui/core';

const TitleWrapper = Styled.div`
	margin: 0 auto;
	width: 800px;
	height: 100%;
	text-align: center;
	padding: ${({ theme }) => theme.spacing(1) / 2}px;
	padding-right: ${({ theme }) => theme.spacing(1)}px;
`;
const Title = Styled.div`
	color: ${({ theme }) => theme.palette.primary.contrastText};
	margin-right: ${({ theme }) => theme.spacing(1)}px;
	font-size: ${({ theme }) => theme.typography.body2.fontSize};
	font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
	letter-spacing: ${({ theme }) => theme.typography.body2.letterSpacing};
`;

const BVB = Styled.a`
	position: absolute;
	background: white;
	top: ${({ theme }) => theme.spacing(0)}px;
	height: ${({ theme }) => theme.spacing(4)}px;
	left: ${({ theme }) => theme.spacing(1)}px;
	border-radius: 0px 0px 8px 8px;
	padding: ${({ theme }) => theme.spacing(1)}px;
	img {
		height: 16px;
	}
`;

export default function Footer() {
	return <>
		<BVB href="https://www.bureauvoorlichtingbinnenvaart.nl/" target="blank">
			<img src={BVBLogoSrc} alt="bvb" />
		</BVB>
		<TitleWrapper>
			<Title>
				<Typography variant="h4">The Blue Road Map</Typography>
				<Typography variant="h6">Your waterway explorer</Typography>
			</Title>
		</TitleWrapper>
	</>;
}
