import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';

import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Collapse, Button } from '@material-ui/core';
import { CarbonEmissionFormatter, DistanceFormatter, DateFormatter, DurationFormatter, ModalityFormatter, ScheduleLabels } from './formatters';
import { Cell } from './Styled';
import Timeline from '../../components/Timeline/index';
import searchResults from '../../reducers/searchResults';
import { useSelector, useDispatch } from 'react-redux';
import requestInfoDialog from '../../reducers/requestInfoDialog';

function getOrderField(orderBy, schedule) {
	switch (orderBy) {
		case 'totalDistance':
			return 'metric.totalDistance';
		case 'totalDuration':
			return 'metric.totalDuration';
		case 'totalEmissions':
			return 'metric.totalEmissions';
		case 'cargoClosing':
			return 'legs[0].cargoClosing';
		case 'cargoAvailable':
			return `legs[${schedule.legs.length - 1}].cargoAvailable`;
		default:
			throw new Error('Cant sort on ' + orderBy);
	}
}

function stableSort(array, order, orderBy) {
	const clone = array.slice();

	clone.sort((a, b) => {
		const keyA = get(a, getOrderField(orderBy, a));
		const keyB = get(b, getOrderField(orderBy, b));

		if (order === 'asc') {
			return (keyA < keyB) ? -1 : (keyA > keyB) ? 1 : 0;
		} else {
			return (keyA < keyB) ? 1 : (keyA > keyB) ? -1 : 0;
		}
	});

	return clone;
}

export default function SchedulesTable({ locatables, modalities, order, orderBy, createSortHandler }) {
	const { schedules: { routes: schedules }, selectedSchedules } = useSelector(state => state.searchResults)
	const dispatch = useDispatch();
	const selectSchedule = (id) => dispatch(searchResults.actions.selectSchedule(id));
	const deselectSchedule = (id) => dispatch(searchResults.actions.deselectSchedule(id));
	const isScheduleSelected = (id) => selectedSchedules.indexOf(id) > -1;
	const requestInfo = (id) => dispatch(requestInfoDialog.actions.show());

	const headCells = [
		{ id: 'cargoClosing', label: <FormattedMessage id="DEPARTURE" />, sort: true },
		{ id: 'cargoAvailable', label: <FormattedMessage id="ARRIVAL" />, sort: true },
		{ id: 'totalDuration', label: <FormattedMessage id="TIME" />, sort: true },
		{ id: 'totalDistance', label: <FormattedMessage id="DISTANCE" />, sort: true },
		{ id: 'totalEmissions', label: <FormattedMessage id="EMISSION" />, sort: true },
		{ id: 'modalities', label: <FormattedMessage id="MODALITIES" /> },
		{ id: 'labels', label: null },
		{ id: 'actions', label: null },
	];

	return (
		<TableContainer style={{ marginBottom: '24px' }}>
			<Table>
				<TableHead>
					<TableRow>
						{headCells.map(headCell => (
							<TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} style={{ fontWeight: '600', fontSize: '13px' }}>
								{headCell.sort ? <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
									{headCell.label}
								</TableSortLabel> : headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{schedules && schedules.length > 0 ? stableSort(schedules, order, orderBy).map((schedule, index) => {
						const selected = isScheduleSelected(schedule.id);
						return (
							<React.Fragment key={index}>
								<TableRow
									hover={true}
									onClick={() => {
										if(selected) {
											deselectSchedule(schedule.id)
										} else {
											selectSchedule(schedule.id);
										}
									}}
									selected={selected}>
									{headCells.map((cell, cellIndex) => {
										switch (cell.id) {
											case 'cargoClosing':
												return <Cell key={cellIndex}><DateFormatter date={schedule.legs[0].cargoClosing} /></Cell>;
											case 'cargoAvailable':
												return <Cell key={cellIndex}><DateFormatter date={schedule.legs[schedule.legs.length - 1].cargoAvailable} /></Cell>;
											case 'totalDuration':
												return <Cell key={cellIndex}><DurationFormatter duration={schedule.metric.totalDuration} /></Cell>;
											case 'totalDistance':
												return <Cell key={cellIndex}><DistanceFormatter distance={schedule.metric.totalDistance} /></Cell>;
											case 'totalEmissions':
												return <Cell key={cellIndex}><CarbonEmissionFormatter emission={schedule.metric.totalEmissions} /></Cell>;
											case 'modalities':
												return <Cell key={cellIndex}><ModalityFormatter modalities={modalities} schedule={schedule} /></Cell>;
											case 'labels':
												return <Cell key={cellIndex}><ScheduleLabels schedules={schedules} schedule={schedule} /></Cell>;
											case 'actions':
												return <Cell key={cellIndex}><Button variant="contained" color="primary" onClick={(e) => {
													e.stopPropagation();
													requestInfo();
												}}>Request Info</Button></Cell>;
											default:
												return <Cell key={cellIndex} />;
										}
									})}
								</TableRow>

								<TableRow>
									<TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
										<Collapse in={selected} timeout="auto" unmountOnExit>
											<Timeline legs={schedule.legs || []} locatables={locatables} schedule={schedule} modalities={modalities} />
										</Collapse>
									</TableCell>
								</TableRow>
							</React.Fragment>
						);
					}) : <TableRow>
						<TableCell colSpan={headCells.length} style={{ textAlign: 'center' }}><FormattedMessage id="NO.RESULTS.FOUND" /></TableCell>
					</TableRow>
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
