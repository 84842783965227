import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Styled from 'styled-components';

import DataService from '../../services/DataService';
import useData from '../../services/useData';
import searchResults from '../../reducers/searchResults';

import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Box, Grid, Collapse, Tab, Tabs, MenuItem, Select, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import moment from 'moment';

const Wrapper = styled(Container)({
	display: 'flex',
	alignItems: 'center',
	height: '100%',
});

const Form = styled(Box)(() => ({
	width: '100%', // Fix IE 11 issue.
}));

const Submit = Styled((props) => <Button type="submit" variant="contained" color="primary" {...props} />)`
	background-color: ${({ theme }) => theme.palette.secondary.main} !important;
	color: #FFF !important;
	margin: ${({ theme }) => theme.spacing(1, 0, 1)};
	opacity: ${props => props.disabled ? '0.5' : '1'};

	&:hover {
		background-color: ${({ theme }) => theme.palette.secondary.dark};
	}
`;

const CollapseButton = Styled((props) => <Button variant="contained" color="primary" {...props} />)`
	background-color: ${({ theme }) => theme.palette.secondary.main};
	margin: ${({ theme }) => theme.spacing(1, 0, 1)};
	float: right;

	&:hover {
		background-color: ${({ theme }) => theme.palette.secondary.dark};
	}
`;

const stylesFn = () => ({
	input: {
		backgroundColor: '#FFF !important',
		borderRadius: '0',
		marginTop: '-5px',
		marginBottom: '10px',

		'& input': {
			padding: '20px 15px !important',
		},
	},

	inputLabel: {
		color: '#FFF !important',
		fontSize: '13px',
		fontWeight: '600',
		lineHeight: '20px',

		'& label': {
			height: '20px',
			float: 'right',
			margin: '0',

			'& span': {
				color: '#FFF',
				fontSize: '11px',
			},
		},
	},

	select: {
		marginTop: '5px',
		marginBottom: '10px',

		'&:before, &:after': {
			borderBottom: 'none !important',
		},
	},

	selectRoot: {
		backgroundColor: '#FFF !important',
		padding: '20px 15px !important',
	},

	selectLabel: {
		color: '#FFF !important',
		fontSize: '13px',
		fontWeight: '600',
		lineHeight: '20px',
	},
});

/* eslint-disable complexity */
const SearchForm = ({ classes, ...props }) => {
	const { data: terminals } = useData(DataService.getLocations, []);
	const dispatch = useDispatch();
	const { search } = useSelector(state => state.searchResults);
	const [ collapsed, collapse ] = useState(false);
	const [ loading, setLoading ] = useState(false);

	const [ fromAddress, setFromAddress ] = useState(search.fromAddress);
	const [ toAddress, setToAddress ] = useState(search.toAddress);

	const { handleSubmit, values, setFieldValue, errors } = useFormik({
		initialValues: {
			...search,
			date: moment(search.date),
			containerCargo: search.cargoType === 'container',
		},
		validationSchema: Yup.object().shape({
			from: Yup.object().nullable(true).when('$fromAddress', {
				is: () => !fromAddress,
				then: Yup.object().required(),
				otherwise: Yup.object().notRequired(),
			}),
			fromAddress: Yup.string().when('$fromAddress', {
				is: () => fromAddress,
				then: Yup.string().required(),
				otherwise: Yup.string().notRequired(),
			}),
			to: Yup.object().nullable(true).when('$toAddress', {
				is: () => !toAddress,
				then: Yup.object().required(),
				otherwise: Yup.object().notRequired(),
			}),
			toAddress: Yup.string().when('$toAddress', {
				is: () => toAddress,
				then: Yup.string().required(),
				otherwise: Yup.string().notRequired(),
			}),
			date: Yup.object().required(),
			containerType: Yup.string().when('containerCargo', {
				is: (containerCargo) => !!containerCargo === true,
				then: Yup.string().required(),
				otherwise: Yup.string().notRequired(),
			}),
			containerAmount: Yup.number().transform((value, oldValue) => oldValue === '' ? null : value).nullable().when('containerCargo', (containerCargo, schema) => {
				return containerCargo
					? schema.required()
					: schema.notRequired();
			}),
			grossWeight: Yup.number().transform((value, oldValue) => oldValue === '' ? null : value).nullable().when('containerCargo', (containerCargo, schema) => {
				return !containerCargo
					? schema.required()
					: schema.notRequired();
			}),
		}),
		onSubmit: submitValues => {
			setLoading(true);

			const fromLocation = !fromAddress ? _.find(terminals, x => x.id === submitValues.from.value) : null;
			const toLocation = !toAddress ? _.find(terminals, x => x.id === submitValues.to.value) : null;

			DataService.getResults({
				from: fromAddress ? submitValues.fromAddress : { id: submitValues.from.value, position: fromLocation.position },
				to: toAddress ? submitValues.toAddress : { id: submitValues.to.value, position: toLocation.position },
				date: submitValues.date,
				cargoType: values.containerCargo ? 'container' : 'drybulk',
				containerAmount: values.containerAmount,
				containerType: values.containerType,
				grossWeight: values.grossWeight,
			}).then((data) => {
				dispatch(searchResults.actions.setResults({
					...data,
					search: {
						date: values.date,
						from: values.from,
						fromAddress: values.fromAddress,
						to: values.to,
						toAddress: values.toAddress,
						cargoType: values.containerCargo ? 'container' : 'drybulk',
						containerAmount: values.containerAmount,
						containerType: values.containerType,
						grossWeight: values.grossWeight,
					},
				}));

				setLoading(false);
			});
		} });

	const containerTypes = [
		'20', '30', '40', '45',
	];
	const options = terminals ? _.sortBy(_.reduce(terminals, (options, terminal) => {
		if ((values.containerCargo && (terminal.tags.length === 0 || _.some(terminal.tags, (x) => x.externalId.indexOf('Container') > -1)))
			|| (!values.containerCargo && (terminal.tags.length === 0 || _.some(terminal.tags, (x) => x.externalId.indexOf('Bulk') > -1)))) {
			return [
				...options,
				{  value: terminal.id, name: terminal.name },
			];
		}

		return options;
	}, []), x => x.name) : [];

	return (
		<Wrapper>
			<Form noValidate onSubmit={handleSubmit} component="form">
				<a style={{ color: 'white', textDecoration: 'none' }} href="/">
					<IconButton style={{ color: 'white' }}><ArrowBackIcon /></IconButton><FormattedMessage id='BACK' />
				</a>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<InputLabel
							classes={{
								root: classes.inputLabel,
							}}>
							<FormattedMessage id="FROM" />
							<FormControlLabel
								control={<Checkbox checked={!!fromAddress} onChange={() => setFromAddress(!fromAddress)} />}
								label={<FormattedMessage id="ADDRESS" />} />
						</InputLabel>

						{fromAddress ?
							<TextField
								fullWidth
								error={!!errors.fromAddress}
								name="fromAddress"
								onChange={(event) => setFieldValue('fromAddress', event.target.value)}
								placeholder={props.intl.formatMessage({ id: 'ENTER.A.ADDRESS' })}
								InputProps={{
									className: classes.input,
								}}
								InputLabelProps={{
									className: classes.inputLabel,
								}}
								margin="normal"
								variant="filled" />
							:
							<Autocomplete
								freeSolo
								options={options}
								getOptionLabel={o => o.name}
								value={values.from}
								onChange={(_, value) => setFieldValue('from', value)}
								renderInput={params => (
									<TextField
										{...params}
										fullWidth
										error={!!errors.from}
										name="from"
										placeholder={props.intl.formatMessage({ id: 'SELECT.TERMINAL' })}
										InputProps={{
											...params.InputProps,
											className: classes.input,
										}}
										InputLabelProps={{
											className: classes.inputLabel,
										}}
										margin="normal"
										variant="filled" />
								)} />
						}
					</Grid>

					<Grid item xs={4}>
						<InputLabel
							classes={{
								root: classes.inputLabel,
							}}>
							<FormattedMessage id="TO" />
							<FormControlLabel
								control={<Checkbox checked={!!toAddress} onChange={() => setToAddress(!toAddress)} />}
								label={<FormattedMessage id="ADDRESS" />} />
						</InputLabel>

						{toAddress ?
							<TextField
								fullWidth
								error={!!errors.toAddress}
								name="toAddress"
								onChange={(event) => setFieldValue('toAddress', event.target.value)}
								placeholder={props.intl.formatMessage({ id: 'ENTER.A.ADDRESS' })}
								InputProps={{
									className: classes.input,
								}}
								InputLabelProps={{
									className: classes.inputLabel,
								}}
								margin="normal"
								variant="filled" />
							:
							<Autocomplete
								freeSolo
								options={options}
								getOptionLabel={o => o.name}
								value={values.to}
								onChange={(_, value) => setFieldValue('to', value)}
								renderInput={params => (
									<TextField
										{...params}
										fullWidth
										error={!!errors.to}
										name="to"
										placeholder={props.intl.formatMessage({ id: 'SELECT.TERMINAL' })}
										InputProps={{
											...params.InputProps,
											className: classes.input,
										}}
										InputLabelProps={{
											className: classes.inputLabel,
										}}
										margin="normal"
										variant="filled" />
								)} />
						}
					</Grid>
				</Grid>

				<Collapse in={collapsed} timeout="auto" unmountOnExit>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<InputLabel
								classes={{
									root: classes.inputLabel,
								}}>
								<FormattedMessage id="DEPARTURE" />
							</InputLabel>

							<DatePicker
								name="date"
								format="DD-MM-YYYY"
								value={values.date}
								onChange={(date) => setFieldValue('date', date)}
								margin="normal"
								inputVariant="filled"
								error={!!errors.date}
								fullWidth
								InputProps={{
									className: classes.input,
								}}
								InputLabelProps={{
									className: classes.inputLabel,
								}} />
						</Grid>
						<Grid item xs={12}>
							{<Tabs
								style={{
									marginBottom: 8,
									color: 'white',
								}} value={ values.containerCargo ? 0 : 1}>
								<Tab color="white" label={<FormattedMessage id="CARGO.CONTAINERS" />} onClick={() => setFieldValue('containerCargo', true)} />
								<Tab label={<FormattedMessage id="CARGO.BULK" />} onClick={() => setFieldValue('containerCargo', false)} />
							</Tabs>}
							{values.containerCargo ?
								<>
									<Grid container spacing={1}>
										<Grid item xs={3}>
											<InputLabel
												classes={{
													root: classes.inputLabel,
												}}>
												<FormattedMessage id="CONTAINER_TYPE" />
											</InputLabel>
											<Select
												className={classes.select}
												classes={{
													root: classes.selectRoot,
													select: classes.select,
												}}
												fullWidth
												error={!!errors.containerType}
												name="containerType"
												value={values.containerType || ''}
												onChange={(event) => setFieldValue('containerType', event.target.value)}>
												{containerTypes.map(x => {
													return <MenuItem key={x} value={x}>{x}ft.</MenuItem>;
												})}
											</Select>
										</Grid>
										<Grid item xs={9}>
											<InputLabel
												classes={{
													root: classes.inputLabel,
												}}>
												<FormattedMessage id="CONTAINER_AMOUNT" />
											</InputLabel>
											<TextField
												fullWidth
												error={!!errors.containerAmount}
												name="containerAmount"
												value={values.containerAmount || ''}
												onChange={(event) => setFieldValue('containerAmount', event.target.value)}
												InputProps={{
													className: classes.input,
												}}
												InputLabelProps={{
													className: classes.inputLabel,
												}}
												margin="normal"
												variant="filled" />
										</Grid>
									</Grid>
								</> :
								<>
									<InputLabel
										classes={{
											root: classes.inputLabel,
										}}>
										<FormattedMessage id="BULK_GROSSWEIGHT" />
									</InputLabel>
									<TextField
										fullWidth
										error={!!errors.grossWeight}
										name="grossWeight"
										value={values.grossWeight || ''}
										onChange={(event) => setFieldValue('grossWeight', event.target.value)}
										InputProps={{
											className: classes.input,
										}}
										InputLabelProps={{
											className: classes.inputLabel,
										}}
										margin="normal"
										variant="filled" />
								</>}
						</Grid>
					</Grid>
				</Collapse>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Submit disabled={loading}>
							<FormattedMessage id="SEARCH" />
							{loading &&	<CircularProgress color="#FFF" size={16} style={{ marginLeft: '10px' }} /> }
						</Submit>

						<CollapseButton onClick={() => collapse(!collapsed)} endIcon={collapsed ? <RemoveIcon /> : <AddIcon />}>
							{collapsed ? <FormattedMessage id="LESS.OPTIONS" /> : <FormattedMessage id="MORE.OPTIONS" />}
						</CollapseButton>
					</Grid>
				</Grid>
			</Form>
		</Wrapper>);
};

export default injectIntl(withStyles(stylesFn)(SearchForm));
