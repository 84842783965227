import { createSlice } from '@reduxjs/toolkit';

const requestInfoDialog = createSlice({
	name: 'requestInfoDialog',
	initialState: {
		shown: false,
	},
	reducers: {
		show: (state) => ({
			...state,
			shown: true,
		}),
		hide: (state) => ({
			...state,
			shown: false,
		}),
	},
});

export default requestInfoDialog;
