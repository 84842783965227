/* eslint-disable */
import { useEffect, useReducer } from 'react';

export default function useData(fn, props) {
	const [ state, setState ] = useReducer((oldState, newState) => ({ ...oldState, ...newState }), {
		loading: false,
		error: false,
		data: null,
	});

	useEffect(() => {
		setState({
			loading: true,
			error: false,
		});

		fn().then(data => {
			setState({
				loading: false,
				error: false,
				data: data,
			});
		}).catch(() => {
			setState({
				loading: false,
				error: true,
				data: null,
			});
		});
	}, props);

	return state;
}
