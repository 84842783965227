import decodePolyline from 'decode-google-map-polyline';

let polylines = [];

function mapPolylines(map, maps, newPolylines, defaultColor) {
	polylines.forEach(p => {
		p.setMap(null);
	});

	polylines = [];

	if (newPolylines) {
		newPolylines.forEach(polyLine => {
			const options = {
				path: polyLine.line,
				strokeColor: polyLine.color || defaultColor,
				strokeOpacity: 1,
				icons: [],
				zIndex: polyLine.dashed ? 1 : 2,
			};

			if (polyLine.dashed) {
				options.strokeOpacity = 0;
				options.icons = [ {
					icon: {
						path: 'M 0,-1 0,1',
						strokeOpacity: 1,
						scale: 3,
					},
					offset: '0',
					repeat: '15px',
				} ];
			}

			const lines = new maps.Polyline(options);
			polylines.push(lines);
			lines.setMap(map);
		});
	}
}

function createBounds(maps, markers, polyLines) {
	const bounds = new maps.LatLngBounds();

	if (markers && markers.length > 0) {
		markers.forEach(m => {
			bounds.extend(new maps.LatLng(m.lat, m.lng));
		});
	}

	if (polyLines && polyLines.length > 0) {
		polyLines.forEach(pl => {
			pl.line.forEach(p => bounds.extend(new maps.LatLng(p.lat, p.lng)));
		});
	}

	return bounds;
}

function calculateBounds(map, maps, markers, props, polyLines) {
	if (!(markers && markers.length > 0) && !(polyLines && polyLines.length > 0)) {
		return;
	}

	const bounds = createBounds(maps, markers, polyLines);
	const objectAmount = ((markers ? markers.length : 0) + (polyLines ? polyLines.length : 0));

	console.log(bounds);
	map.fitBounds(bounds);

	if (objectAmount < 2) {
		//map.setZoom(props.zoom);
	}
}

export function handleMapChange(map, maps, props) {
	let newPolylines = [];

	if (props.polyLines) {
		newPolylines = props.polyLines;
	}

	if (props.encodedPolyLines) {
		newPolylines = [
			...newPolylines,
			...props.encodedPolyLines.map(l => {
				const decLine = decodePolyline(l.line);
				return {
					color: l.color,
					dashed: l.dashed,
					line: decLine,
				};
			}),
		];
	}

	mapPolylines(map, maps, newPolylines, props.polyLineColor);

	if (!props.disableBounds) {
		calculateBounds(map, maps, props.markers, props, newPolylines);
	}
}
