import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useSelector } from 'react-redux';

export default ({ children }) => {
	const language = useSelector(state => state.language.key);
	return <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
		{children}
	</MuiPickersUtilsProvider>;
};
