import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTruck,
	faShip,
	faTrain,
	faPlane,
	faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import { CarbonEmissionFormatter, CostFormatter } from '../../pages/results/formatters';

const DateFormatter = ({ date }) => moment(date).format('DD-MM HH:mm');

const Timeline = ({ legs, locatables, modalities }) => {
	const [ actions, setActions ] = useState([]);

	const getLocation = (id) => {
		const location =  locatables.find(l => l.key.id === id);
		return location ? location.name : undefined;
	};

	const getModality = (id) => {
		const modality =  modalities.find(m => m.id === id);
		return modality ? modality.externalId : undefined;
	};

	const buildStop = (location, time) => {
		return (
			<div className="timeline-action-container">
				<div class="timeline-action">
					<div class="timeline-action-stop not-started">
						<FontAwesomeIcon icon={faMapMarkerAlt} />
					</div>

					<div class="timeline-action-foot">
						<span>{location}</span>
						<span>{time}</span>
					</div>
				</div>
			</div>
		);
	};

	const buildModality = (modality, leg) => {
		let icon = null;

		if (modality === 'SHIP' || modality === 'BARGE' || modality === 'SHORTSEA' || modality === 'DEEPSEA') {
			icon = <FontAwesomeIcon icon={faShip} />;
		} else if (modality === 'TRUCK') {
			icon = <FontAwesomeIcon icon={faTruck} />;
		} else if (modality === 'TRAIN' || modality === 'TRAIN_ELECTRIC') {
			icon = <FontAwesomeIcon icon={faTrain} />;
		} else if (modality === 'AIRCRAFT') {
			icon = <FontAwesomeIcon icon={faPlane} />;
		}

		return (
			<div class="timeline-modality-container">
				<p>
					<CarbonEmissionFormatter emission={leg.emissions} />
					<CostFormatter cost={leg.monetaryCost} />
				</p>

				<div class="timeline-modality not-started">
					{icon}
				</div>
			</div>
		);
	};

	const createLegs = () => {
		const elements = [];

		legs.forEach((leg, index) => {
			elements.push(buildStop(getLocation(leg.origin), <DateFormatter date={leg.cargoClosing} />));
			elements.push(buildModality(getModality(leg.modalities[0]), leg));

			if (index === legs.length - 1) {
				elements.push(buildStop(getLocation(leg.destination), <DateFormatter date={leg.cargoAvailable} />));
			}
		});

		setActions(elements);
	};

	useEffect(createLegs, [ legs ]);

	return (
		<div class="timeline">
			<div className="scroll">
				{actions}
			</div>
		</div>
	);
};

export default Timeline;
