import React from 'react';
import Styled from 'styled-components';
import { Box, styled, TableCell, Tabs } from '@material-ui/core';

export const Cell = Styled((props) => <TableCell {...props}>{props.children}</TableCell>)`
	font-size: 13px;
`;

export const Panel = styled(Box)({
	overflowY: 'auto',
});

export const BoxLeft = Styled((props) => <Box flex={1} boxSizing="border-box" {...props}>{props.children}</Box>)`
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-flow: column;
	height: 100%;
	position: relative;
	z-index: 1;
`;
export const Header = Styled((props) => <Box p={3} {...props}>{props.children}</Box>)`
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const Content = Styled((props) => <Box p={3} {...props}>{props.children}</Box>)`
	background-color: #FFF;
	flex-grow: 1;
	overflow-y: auto;

	scrollbar-color: #A3A3A3 #D7D7D7;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: #D7D7D7;
	}

	&::-webkit-scrollbar-track {
		border: none;
		background-color: #D7D7D7;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #A3A3A3;
	}
`;

export const TabBar = styled(Tabs)(({ theme }) => ({
	marginTop: -theme.spacing(3),
}));
