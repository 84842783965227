import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Styled from 'styled-components';

import DataService from '../../services/DataService';
import useData from '../../services/useData';
import searchResults from '../../reducers/searchResults';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Tabs, Tab, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import BlueRoadMapLogoSrc from '../../img/logo_blueroad.png';

const Wrapper = styled(Container)({
	display: 'flex',
	alignItems: 'center',
	height: '100%',
});

const FormWrapper = styled(Paper)(({ theme }) => {
	return ({
		backgroundColor: '#FFF',
		borderRadius: '4px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '50px',
		marginBottom: '30px',
		width: '100%',
		padding: 0,
		overflow: 'hidden',
	});
});

const FormHeader = Styled.div`
	background: #e8e8e8;
	width: 100%;
	padding: ${({ theme }) => theme.spacing(2)}px;
	text-align: center;
	img {
		margin-bottom: ${({ theme }) => theme.spacing(2)}px;
	}
`;

const stylesFn = theme => ({
	input: {
		borderRadius: '0',
		marginBottom: '10px',
		marginTop: '-11px',

		'&:before': {
			borderBottom: '2px solid #C1C1C1',
			transition: 'all 0.3s ease',
		},

		'&:hover': {
			'&:before': {
				borderBottom: `2px solid ${theme.palette.primary.main}`,
			},
		},

		'& input': {
			padding: '20px 15px !important',
		},
	},

	inputLabel: {
		color: `${theme.palette.primary.main} !important`,
		fontSize: '13px',
		fontWeight: '600',
		lineHeight: '20px',

		'& label': {
			height: '20px',
			float: 'right',
			margin: '0',

			'& span': {
				fontSize: '11px',
			},
		},
	},

	select: {
		borderRadius: '0',
		marginBottom: '15px',

		'&:before': {
			borderBottom: '2px solid #C1C1C1',
			transition: 'all 0.3s ease',
		},

		'&:hover': {
			'&:before': {
				borderBottom: `2px solid ${theme.palette.primary.main}`,
			},
		},
	},

	selectRoot: {
		backgroundColor: 'rgba(0, 0, 0, 0.09)',
		padding: '20px 15px',
		marginTop: '5px',
		marginBottom: '0',
	},
});

const Submit = Styled((props) => <Button type="submit" fullWidth variant="contained" color="primary" {...props} />)`
	background-color: ${({ theme }) => theme.palette.secondary.main};
	margin: ${({ theme }) => theme.spacing(2, 0, 2)};

	&:hover {
		background-color: ${({ theme }) => theme.palette.secondary.dark};
	}
`;

const Form = styled(Box)(({ theme }) => ({
	width: '100%', // Fix IE 11 issue.
	padding: theme.spacing(2),
	marginTop: theme.spacing(1),
}));

const SearchForm = ({ classes, ...props }) => {
	const { data: terminals } = useData(DataService.getLocations, []);
	const dispatch = useDispatch();

	const [ fromAddress, setFromAddress ] = useState(false);
	const [ toAddress, setToAddress ] = useState(false);
	//const [ collapsed, collapse ] = useState(false);
	const [ loading, setLoading ] = useState(false);

	const { handleSubmit, values, setFieldValue, errors } = useFormik({
		initialValues: {
			from: null,
			fromAddress: '',
			to: null,
			toAddress: '',
			date: moment(),
			containerCargo: true,
		},
		validationSchema: Yup.object().shape({
			from: Yup.object().nullable(true).when('$fromAddress', {
				is: () => !fromAddress,
				then: Yup.object().required(),
				otherwise: Yup.object().notRequired(),
			}),
			fromAddress: Yup.string().when('$fromAddress', {
				is: () => fromAddress,
				then: Yup.string().required(),
				otherwise: Yup.string().notRequired(),
			}),
			to: Yup.object().nullable(true).when('$toAddress', {
				is: () => !toAddress,
				then: Yup.object().required(),
				otherwise: Yup.object().notRequired(),
			}),
			toAddress: Yup.string().when('$toAddress', {
				is: () => toAddress,
				then: Yup.string().required(),
				otherwise: Yup.string().notRequired(),
			}),
			date: Yup.object().required(),
			containerType: Yup.string().when('containerCargo', {
				is: (containerCargo) => !!containerCargo === true,
				then: Yup.string().required(),
				otherwise: Yup.string().notRequired(),
			}),
			containerAmount: Yup.number().transform((value, oldValue) => oldValue === '' ? null : value).nullable().when('containerCargo', (containerCargo, schema) => {
				return containerCargo
					? schema.required()
					: schema.notRequired();
			}),
			grossWeight: Yup.number().transform((value, oldValue) => oldValue === '' ? null : value).nullable().when('containerCargo', (containerCargo, schema) => {
				return !containerCargo
					? schema.required()
					: schema.notRequired();
			}),
		}),
		onSubmit: submitValues => {
			setLoading(true);

			const fromLocation = !fromAddress ? _.find(terminals, x => x.id === submitValues.from.value) : null;
			const toLocation = !toAddress ? _.find(terminals, x => x.id === submitValues.to.value) : null;


			DataService.getResults({
				from: fromAddress ? submitValues.fromAddress : { id: submitValues.from.value, position: fromLocation.position },
				to: toAddress ? submitValues.toAddress : { id: submitValues.to.value, position: toLocation.position },
				date: submitValues.date,
				cargoType: values.containerCargo ? 'container' : 'drybulk',
				containerAmount: values.containerAmount,
				containerType: values.containerType,
				grossWeight: values.grossWeight,

			}).then((data) => dispatch(searchResults.actions.setResults({
				...data,
				search: {
					date: values.date,
					from: values.from,
					fromAddress: values.fromAddress,
					to: values.to,
					toAddress: values.toAddress,
					cargoType: values.containerCargo ? 'container' : 'drybulk',
					containerAmount: values.containerAmount,
					containerType: values.containerType,
					grossWeight: values.grossWeight,
				},
			})));
		} });

	const containerTypes = [
		'20', '30', '40', '45',
	];
	const options = terminals ? _.sortBy(_.reduce(terminals, (options, terminal) => {
		if ((values.containerCargo && (terminal.tags.length === 0 || _.some(terminal.tags, (x) => x.externalId.indexOf('Container') > -1)))
		|| (!values.containerCargo && (terminal.tags.length === 0 || _.some(terminal.tags, (x) => x.externalId.indexOf('Bulk') > -1)))) {
			return [
				...options,
				{  value: terminal.id, name: terminal.name },
			];
		}

		return options;
	}, []), x => x.name) : [];

	return (
		<Wrapper maxWidth="xs">
			<CssBaseline />
			<FormWrapper>
				<FormHeader>
					<img src={BlueRoadMapLogoSrc} alt="BlueRoadMap" />
					<Typography component="h2" variant="h6" color="textSecondary" gutterBottom={true}>
						<FormattedMessage id="HOME.PLANTITLE" />
					</Typography>
				</FormHeader>

				<Form noValidate onSubmit={handleSubmit} component="form">
					{<Tabs
						style={{
							marginBottom: 8,
						}} value={ values.containerCargo ? 0 : 1}>
						<Tab label={<FormattedMessage id="CARGO.CONTAINERS" />} onClick={() => setFieldValue('containerCargo', true)} />
						<Tab label={<FormattedMessage id="CARGO.BULK" />} onClick={() => setFieldValue('containerCargo', false)} />
					</Tabs>}

					<InputLabel
						classes={{
							root: classes.inputLabel,
						}}>
						<FormattedMessage id="FROM" />
						<FormControlLabel
							control={<Checkbox checked={!!fromAddress} onChange={() => setFromAddress(!fromAddress)} />}
							label={<FormattedMessage id="ADDRESS" />} />
					</InputLabel>

					{fromAddress ?
						<TextField
							fullWidth
							error={!!errors.fromAddress}
							name="fromAddress"
							onChange={(event) => setFieldValue('fromAddress', event.target.value)}
							placeholder={props.intl.formatMessage({ id: 'ENTER.A.ADDRESS' })}
							InputProps={{
								className: classes.input,
							}}
							InputLabelProps={{
								className: classes.inputLabel,
							}}
							margin="normal"
							variant="filled" />
						:
						<Autocomplete
							freeSolo
							options={options}
							getOptionLabel={o => o.name}
							value={values.from}
							onChange={(_, value) => setFieldValue('from', value)}
							renderInput={params => (
								<TextField
									{...params}
									fullWidth
									error={!!errors.from}
									name="from"
									placeholder={props.intl.formatMessage({ id: 'SELECT.TERMINAL' })}
									InputProps={{
										...params.InputProps,
										className: classes.input,
									}}
									InputLabelProps={{
										className: classes.inputLabel,
									}}
									margin="normal"
									variant="filled" />
							)} />
					}

					<InputLabel
						classes={{
							root: classes.inputLabel,
						}}>
						<FormattedMessage id="TO" />
						<FormControlLabel
							control={<Checkbox checked={!!toAddress} onChange={() => setToAddress(!toAddress)} />}
							label={<FormattedMessage id="ADDRESS" />} />
					</InputLabel>

					{toAddress ?
						<TextField
							fullWidth
							error={!!errors.toAddress}
							name="toAddress"
							onChange={(event) => setFieldValue('toAddress', event.target.value)}
							placeholder={props.intl.formatMessage({ id: 'ENTER.A.ADDRESS' })}
							InputProps={{
								className: classes.input,
							}}
							InputLabelProps={{
								className: classes.inputLabel,
							}}
							margin="normal"
							variant="filled" />
						:
						<Autocomplete
							freeSolo
							options={options}
							getOptionLabel={o => o.name}
							value={values.to}
							onChange={(_, value) => setFieldValue('to', value)}
							renderInput={params => (
								<TextField
									{...params}
									fullWidth
									error={!!errors.to}
									name="to"
									placeholder={props.intl.formatMessage({ id: 'SELECT.TERMINAL' })}
									InputProps={{
										...params.InputProps,
										className: classes.input,
									}}
									InputLabelProps={{
										className: classes.inputLabel,
									}}
									margin="normal"
									variant="filled" />
							)} />
					}

					<InputLabel
						classes={{
							root: classes.inputLabel,
						}}>
						<FormattedMessage id="DEPARTURE" />
					</InputLabel>

					<DatePicker
						name="date"
						format="DD-MM-YYYY"
						value={values.date}
						onChange={(date) => setFieldValue('date', date)}
						margin="normal"
						inputVariant="filled"
						error={!!errors.date}
						fullWidth
						InputProps={{
							className: classes.input,
						}}
						InputLabelProps={{
							className: classes.inputLabel,
						}} />

					{values.containerCargo ?
						<>
							<Grid container spacing={1}>
								<Grid item xs={3}>
									<InputLabel
										classes={{
											root: classes.inputLabel,
										}}>
										<FormattedMessage id="CONTAINER_TYPE" />
									</InputLabel>
									<Select
										className={classes.select}
										classes={{
											root: classes.selectRoot,
											select: classes.select,
										}}
										fullWidth
										error={!!errors.containerType}
										name="containerType"
										value={values.containerType || ''}
										onChange={(event) => setFieldValue('containerType', event.target.value)}>
										{containerTypes.map(x => {
											return <MenuItem key={x} value={x}>{x}ft.</MenuItem>;
										})}
									</Select>
								</Grid>
								<Grid item xs={9}>
									<InputLabel
										classes={{
											root: classes.inputLabel,
										}}>
										<FormattedMessage id="CONTAINER_AMOUNT" />
									</InputLabel>
									<TextField
										fullWidth
										error={!!errors.containerAmount}
										name="containerAmount"
										value={values.containerAmount || ''}
										onChange={(event) => setFieldValue('containerAmount', event.target.value)}
										InputProps={{
											className: classes.input,
										}}
										InputLabelProps={{
											className: classes.inputLabel,
										}}
										margin="normal"
										variant="filled" />
								</Grid>
							</Grid>
						</> :
						<>
							<InputLabel
								classes={{
									root: classes.inputLabel,
								}}>
								<FormattedMessage id="BULK_GROSSWEIGHT" />
							</InputLabel>
							<TextField
								fullWidth
								error={!!errors.grossWeight}
								name="grossWeight"
								value={values.grossWeight || ''}
								onChange={(event) => setFieldValue('grossWeight', event.target.value)}
								InputProps={{
									className: classes.input,
								}}
								InputLabelProps={{
									className: classes.inputLabel,
								}}
								margin="normal"
								variant="filled" />
						</>}

					{/* <CollapseButton>
						<Typography component="h6" variant="h6" onClick={() => collapse(!collapsed)}>
							<span>{collapsed ? <FormattedMessage id="LESS.OPTIONS" /> : <FormattedMessage id="MORE.OPTIONS" />}</span>
							{collapsed ? <RemoveIcon /> : <AddIcon />}
						</Typography>
					</CollapseButton>

					<Collapse in={collapsed} fullWidth timeout="auto" unmountOnExit>
						<InputLabel
							classes={{
								root: classes.inputLabel,
							}}>
							<FormattedMessage id="OPTIMIZATION" />
						</InputLabel>

						<Select
							className={classes.select}
							classes={{
								root: classes.selectRoot,
								select: classes.select,
							}}
							fullWidth
							name="optimization"
							value={values.optimization}
							onChange={(event) => setFieldValue('optimization', event.target.value)}>
							<MenuItem value=""><em><FormattedMessage id="NONE" /></em></MenuItem>
							<MenuItem value={'EMISSIONS'}><FormattedMessage id="EMISSIONS" /></MenuItem>
							<MenuItem value={'TIME'}><FormattedMessage id="TIME" /></MenuItem>
							<MenuItem value={'COSTS'}><FormattedMessage id="COSTS" /></MenuItem>
						</Select>
					</Collapse> */}


					<Submit disabled={loading}>
						<FormattedMessage id="SEARCH" />
						{loading &&	<CircularProgress color="#FFF" size={16} style={{ marginLeft: '10px' }} /> }
					</Submit>
				</Form>
			</FormWrapper>
		</Wrapper>
	);
};

export default injectIntl(withStyles(stylesFn)(SearchForm));
