import background from '../img/background.jpg';
import bvb_background_1 from '../img/bvb/background_1.jpg';

// eslint-disable-next-line no-unused-vars
const bgsCeva = [
	background,
];

const bgsBvb = [
	bvb_background_1,
];

export default function getRandomImage() {
	const bgs = bgsBvb;
	return bgs[Math.floor(Math.random() * bgs.length)];
}
