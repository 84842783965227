import React, { useState, useEffect } from 'react';
import GoogleMap from 'google-map-react';
import { handleMapChange } from './utils';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SluiceMarker from './SluiceMarker';

const styles = require('../../components/Map/styles.json');

const fetchPolylines = (schedule, polylines) => {
	const result = [];
	if (schedule && polylines) {
		for (let i = 0; i < schedule.legs.length; i++) {
			if (schedule.legs[i].polyline || schedule.legs[i].polyline === 0) {
				result.push(polylines.find(pl => pl.id === schedule.legs[i].polyline).polyline);
			}
		}
	}

	return result;
};

const fetchLocations = (schedules, locations) => {
	const result = [];

	if (schedules && locations) {
		for (let s = 0 ; s < schedules.length; s++) {
			const schedule = schedules[s];

			for (let i = 0; i < schedule.legs.length; i++) {
				if (schedule.legs[i].origin) {
					const location = locations.find(l => l.key.id === schedule.legs[i].origin);
					if (location) {
						result.push(location);
					}
				}
				if (schedule.legs[i].destination) {
					const location = locations.find(l => l.key.id === schedule.legs[i].destination);
					if (location) {
						result.push(location);
					}
				}
			}
		}
	}

	return _.uniqBy(result, x => x.key.id);
};

const Map = ({ routesShown }) => {
	const [ mapref, setMap ] = useState();
	const [ mapsref, setMaps ] = useState();
	const { routes, schedules: { routes: schedules, locatables, polylines }, selectedSchedules, selectedRoute } = useSelector(state => state.searchResults);

	useEffect(() => {
		if (mapref && mapsref) {
			let shownPolylines = [];

			if (!routesShown) {
				shownPolylines = schedules.reduce((lines, schedule) => {
					const isActive = selectedSchedules.indexOf(schedule.id) > -1;
					return [
						...lines,
						...fetchPolylines(schedule, polylines).map(x => ({
							line: x,
							color: isActive ? '#104E8B' : 'gray',
							dashed: !isActive,
						})),
					];
				}, shownPolylines);
			} else {
				shownPolylines = routes.map((route, index) => ({
					line: route.route.polyline,
					color: selectedRoute === index ? '#104E8B' : 'gray',
					dashed: selectedRoute !== index,
				}));
			}

			handleMapChange(mapref, mapsref, {
				encodedPolyLines: shownPolylines,
				zoom: 9,
			});
		}
	}, [ mapref, mapsref, schedules, polylines, selectedSchedules, selectedRoute, routes, routesShown ]);

	const sluicesToShow = routesShown ? Object.values(routes.reduce((obj, routeholder, index) => {
		if (selectedRoute === index) {
			routeholder.route.sluices.forEach(sluice => {
				if (!obj[sluice.sluiceId]) {
					obj[sluice.sluiceId] = sluice;
				}
			});
		}

		return obj;
	}, {})) : [];

	return <GoogleMap
		onGoogleApiLoaded={({ map, maps }) => {
			setMaps(maps);
			setMap(map);
		}}
		apiKey={'AIzaSyAFEhlhSJ7c85rDGz1JX99veD0sshXx_xs'}
		yesIWantToUseGoogleMapApiInternals={true}
		defaultCenter={{ lat: 51.503738060232095, lng: 0.830635929687471 }}
		options={{
			styles: styles,
		}}
		zoom={9}>
		{fetchLocations(schedules.filter(r => selectedSchedules.indexOf(r.id) > - 1), locatables).map((location, i) => {
			if (location && location.position && location.position.latitude && location.position.longitude) {
				return (
					<div
						key={i}
						lat={location.position.latitude}
						lng={location.position.longitude}
						style={{
							backgroundColor: '#FFF',
							border: '3px solid #104E8B',
							width: '20px',
							height: '20px',
							borderRadius: '20px',
							position: 'absolute',
							transform: 'translate(-50%, -50%)',
						}} />
				);
			}

			return null;
		}).filter(x => x != null)}
		{sluicesToShow.map(sluice => <SluiceMarker
			key={sluice.sluiceId}
			lat={sluice.latitude}
			lng={sluice.longitude}
			sluice={sluice} />)}
	</GoogleMap>;
};

export default Map;
