export default {
	getLocations: async() => {
		const locations = await fetch('/api/locations', {
			method: 'GET',
		});

		return await locations.json();
	},
	getResults: async({ date, from, to, cargoType, containerType, containerAmount, grossWeight }) => {
		const data = {
			'departureAt': date,
			'origin': from.id ? {
				id: from.id,
				position: (from.position && from.position.latitude && from.position.longitude) ? { lat: from.position.latitude, lng: from.position.longitude } : null,
			} : null,
			'originFreeform': !from.id ? from : null,
			'destination': to.id ? {
				id: to.id,
				position: (to.position && to.position.latitude && to.position.longitude) ? { lat: to.position.latitude, lng: to.position.longitude } : null,
			} : null,
			'destinationFreeform': !to.id ? to : null,
			'cargoType': cargoType,
			'containerType': containerType,
			'containerAmount': containerAmount,
			'grossWeight': grossWeight,
			'specificWeight': 1,
			'covered': false,
			'volume': null,
		};

		const request = await fetch('/api/request', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		return request.json();
	},
	sendRequestInfo: async(email, remarks, searchData) => {
		try {
			const request = await fetch('/api/request-info', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
					remarks,
					searchData,
				}),
			});
			return request.status === 200;
		} catch (e) {
			return false;
		}
	},
};
