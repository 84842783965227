import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as ReduxProvider } from 'react-redux';
// import theme from '@rebass/preset'
import LocaleWrapper from './LocaleWrapper';
import { store, history } from './redux';
import App from './pages/App';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@cofano/cck/Theme';
import theme from './styling/theme';
import MuiPickersUtilsProviderWrapper from './MuiPickersUtilsProviderWrapper';

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<ReduxProvider store={store}>
			<LocaleWrapper>
				<MuiPickersUtilsProviderWrapper>
					<ConnectedRouter history={history}>
						<App />
					</ConnectedRouter>
				</MuiPickersUtilsProviderWrapper>
			</LocaleWrapper>
		</ReduxProvider>
	</ThemeProvider>,
	document.getElementById('root')
);
