import React from 'react';
import {
	HashRouter as Router,
	Switch,
} from 'react-router-dom';

import Search from './search';
import Results from './results';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Styled from 'styled-components';
import getRandomImage from '../styling/getRandomImage';
import Footer from '../components/Footer';
import Header from '../components/Header';
import DisclaimerDialog from '../components/Disclaimer/DisclaimerDialog';

const Wrapper = Styled(Box)`
	min-height: 100%;
	overflow-y: auto;
	background-image: url(${props => props.image});
	background-size: cover;
	background-position: center;
	position: relative;
	z-index: 0;
	display: flex;
	flex-direction: column;
`;

const Overlay = styled(Box)({
	background: '#000000',
	bottom: '0',
	position: 'absolute',
	left: '0',
	opacity: '0.0',
	top: '0',
	width: '100%',
	zIndex: '-1',
});

const Content = Styled.div`
	display: flex;
	overflow-y: auto;
	flex: 1;
`;
const FooterWrapper = Styled.div`
	height: ${({ theme }) => theme.spacing(4)}px;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

const HeaderWrapper = Styled.div`
	position: relative;
	height: ${({ theme }) => theme.spacing(12)}px;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

const App = () => {
	const { routes } = useSelector(state => state.searchResults);

	return (
		<Wrapper image={getRandomImage()} >
			<Overlay />
			<DisclaimerDialog />
			{!routes && <HeaderWrapper>
				<Header />
			</HeaderWrapper>}
			<Content>
				<Router>
					<Switch>
						{ routes ? <Results /> : <Search /> }
					</Switch>
				</Router>
			</Content>
			<FooterWrapper>
				<Footer />
			</FooterWrapper>
		</Wrapper>
	);
};

export default App;
